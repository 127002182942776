
import CloudFun, {
  defineComponent,
  ref,
} from "@cloudfun/core";
import Grid, { GridOptions } from "@/cloudfun/components/Grid.vue";
import FileUploader from "@/cloudfun/components/FileUploader.vue";
import ColorGrid from "@/components/product/Color.vue";
import PictureAlbum from "@/components/product/Album.vue";
import { VueUploadItem } from "vue-upload-component";

export default defineComponent({
  components: {
    Grid,
    FileUploader,
    ColorGrid,
    PictureAlbum
  },
  setup() {
    const model = CloudFun.current?.model;
    const grid = ref<any>({});
    const uploader = ref<any>({});

    var printColumns = [
      { field: "Name" },
      { field: "Author" },
      { field: "Price" },
      { field: "MemberPrice" },
      { field: "PublishedString" },
      { field: "Ordinal" },
    ];

    const gridOptions: GridOptions = {
      title: "產品資料",
      canCreate: true,
      canUpdate: true,
      multiselect: false,
      toolbarConfig: {
        custom: true,
        refresh: true,
      },
      // pagerConfig: {
      //   currentPage: 1,
      //   pageSize: 10,
      //   pageSizes: [5, 10, 20],
      //   layouts: [
      //     "PrevJump",
      //     "PrevPage",
      //     "Jump",
      //     "PageCount",
      //     "NextPage",
      //     "NextJump",
      //     "Sizes",
      //     "Total",
      //   ],
      // },
      printConfig: {
        sheetName: "產品清單",
        columns: printColumns,
        modes: ["current", "selected", "all"],
      },
      exportConfig: {
        filename: "產品清單",
        type: "csv",
        types: ["html", "csv"],
        mode: "all",
        modes: ["current", "selected", "all"],
        columns: printColumns,
      },
      columns: [
        {
          field: "Number",
          title: "品號",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
          width: "180",
          fixed: "left",
          editRender: {
            name: "$input",
            immediate: true,
            attrs: { type: "text" },
          },
        },
        {
          field: "Name",
          title: "品名",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
          minWidth: "150",
          editRender: {
            name: "$input",
            immediate: true,
            attrs: { type: "text" },
          },
        },
        // {
        //   field: "Country.Name",
        //   title: "產地",
        //   showHeaderOverflow: true,
        //   showOverflow: true,
        //   sortable: true,
        //   width: "180",
        //   editRender: {
        //     name: "$input",
        //     immediate: true,
        //     attrs: { type: "text" },
        //   },
        // },
        // {
        //   field: "Ordinal",
        //   title: "排序值",
        //   showHeaderOverflow: true,
        //   showOverflow: true,
        //   sortable: true,
        //   width: "100",
        //   editRender: {
        //     name: "$input",
        //     immediate: true,
        //     attrs: { type: "text" },
        //   },
        // },
        {
          field: "Published",
          title: "上架",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
          width: "100",
          resizable: false,
          formatter({ cellValue }) {
            return cellValue ? "上架" : "下架";
          }
        },
      ],
      promises: {
        query: model ? (params) => model.dispatch("product/query", params) : undefined,
        queryAll: model ? (params) => model.dispatch("product/query", params) : undefined,
        save: model ? (params) => model.dispatch("product/save", params) : undefined,
      },
      modalConfig: { width: "80%", height: "80%" },
    };
    const formOptions = {
      items: [
        { field: "Photo.Uri", title: "圖片", span: 24, slots: { default: "column-photo-and-remark" } },
        {
          field: "Number",
          title: "產品編號",
          span: 12,
          itemRender: { name: "$input", props: { placeholder: "請輸入文字" } },
        },
        {
          field: "Name",
          title: "產品名稱",
          span: 12,
          itemRender: { name: "$input", props: { placeholder: "請輸入文字" } },
        },
        {
          field: "SalePice",
          title: "價格",
          span: 12,
          itemRender: { name: "$input", props: { type: "number", placeholder: "請輸入價格" } },
        },
        { field: "Published", title: "是否上架", span: 12, itemRender: { name: "$switch", props: { openLabel: '上架', closeLabel: '下架' } } },
        {
          field: "Description.Content",
          title: "產品介紹",
          span: 24,
          itemRender: { name: "$textarea", props: { placeholder: "請輸入產品介紹" } },
        },
        {
          span: 24,
          align: "right",
          itemRender: {
            name: "$buttons",
            children: [
              { props: { type: "submit", content: "確定", status: "primary" } },
              { props: { type: "reset", content: "重置" } },
            ],
          },
        },
      ],
      rules: {
        Name: [{ required: true }],
        // Price: [{ type: "number", required: true, message: "售價" }],
        // MemberPrice: [{ type: "number", required: true, message: "會員價" }],
        // Published: [{ required: true }],
      },
    };

    const submitForm = async () => {
      if (grid.value.editingRow.Id) {
        grid.value.editingRow = await model?.dispatch("product/update", grid.value.editingRow);
      } else {
        grid.value.editingRow = await model?.dispatch("product/insert", grid.value.editingRow);
      }
      grid.value.reload();
    }

    return {
      grid,
      uploader,
      gridOptions,
      formOptions,
      submitForm,
      uploadAction: `${process.env.VUE_APP_BACKEND_URL}/api/Files`,
    };
  },
  methods: {
    async onGridEdit(row: any, callback: any) {
      const entity = row.Id
        ? await this.$model.dispatch("product/find", row.Id)
        : undefined;

      if (entity) Object.assign(row, entity);
      callback();
    },
    onGridRemoveSelectedRows(rows: any, callback: any) {
      cash("#batch-dropdown").dropdown("hide");
      callback();
    },
    async uploaderFilter(current: VueUploadItem, original: VueUploadItem, prevent: any) {
      if (!current || !current.name) return prevent();
      if (!/\.(png|gif|jpg|jpeg)$/i.test(current.name)) {
        alert("未支援此種圖片格式");
        return prevent();
      }
    }
  },
});
