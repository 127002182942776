
import CloudFun, { Condition, defineComponent, Operator, ref, watch } from '@cloudfun/core'
import SliderEditor from '@/cloudfun/components/SliderEditor.vue'

export default defineComponent({
  props: {
    albumId: {
      type: Number,
      required: true,
    },
  },
  components: {
    SliderEditor
  },
  setup(props) {
    const model = CloudFun.current?.model
    const sliderEditor = ref<any>();

    const pictureFormOptions = {
      titleWidth: 40,
      titleAlign: 'right',
      items: [
        {
          field: 'Name',
          title: '名稱',
          span: 14,
          itemRender: { name: '$input', props: { placeholder: '請輸入文字', clearable: true } }
        }, {
          field: 'Ordinal',
          title: '順序',
          span: 10,
          titleWidth: 40,
          itemRender: { name: '$input', props: { type: 'number', placeholder: '請輸入數字' } }
        }, {
          field: 'Remark',
          title: '備註',
          span: 24,
          itemRender: { name: '$input', props: { placeholder: '請輸入文字', clearable: true } }
        },
      ],
      rules: {
        Name: [{ type: 'string', max: 128 }]
      }
    }

    const loadData = async (key: number) => {
      try {
        const photos = await model?.dispatch("picture/query", {
          condition: new Condition("AlbumId", Operator.Equal, key),
          sortings: [{ column: "Ordinal", order: 0 }]
        });
        sliderEditor.value.reload(photos);
      } catch (error: any) {
        CloudFun.send("error", error);
      }
    }

    watch(() => props.albumId, (value) => {
      if (!value) return;
      loadData(value);
    }, { immediate: true })

    const save = async (params: any, callback: any) => {
      await model?.dispatch('picture/save', params).then(
        async () => {
          loadData(props.albumId);
        },
        reason => { CloudFun.send('error', { subject: '保存失敗', content: reason }) }
      )
      callback()
    }

    return {
      sliderEditor,
      pictureFormOptions,
      uploadAction: `${process.env.VUE_APP_BACKEND_URL}/api/Files`,
      save
    }
  },
  methods: {
    onSliderEdit(image: any, callback: any) {
      image.AlbumId = this.albumId;
      callback()
    },
    async onSliderRemove(image: any, callback: any) {
      await this.$model.dispatch('picture/delete', image.Id)
      callback()
    },
  }
})
