<template>
  <!-- BEGIN: HTML Table Data -->
  <div>
    <div class="grid grid-cols-12 gap-6 mt-8">
      <div class="col-span-12 lg:col-span-4 xxl:col-span-3">
        <h2 class="intro-y text-lg font-medium mr-auto mt-2">產品顏色/尺寸</h2>
        <!-- BEGIN: File Manager Menu -->
        <div class="intro-y box p-5 mt-0">
          <a href="javascript:void()" class="flex items-center px-3 py-2 mt-2 rounded-md" @click="colorOptions.add">
            <PlusIcon class="w-4 h-4 mr-2" /> 新增顏色
          </a>
          <div class="border-t border-gray-200 dark:border-dark-5 mt-5 pt-5">
            <div class="flex items-center px-3 py-2 rounded-md" v-for="(item, index) in productColors" :key="index"
              @click="colorOptions.select(item)" :class="[
                selectedColor.Id === item.Id ? 'bg-theme-1 text-white' : '',
              ]">
              <div class="w-5 h-3 rounded-full mr-3 border" :style="`background-color:${item.Color?.HexValue}`"></div>
              <div class="w-full">
                {{ item.Color?.Name }}_{{ item.Color?.Number }}
              </div>
              <div class="flex justify-end w-24">
                <button class="button rounded-full mr-1 bg-blue-600 text-white" title="編輯"
                  @click.stop="colorOptions.edit(item)">
                  <FontAwesome icon="highlighter" class="w-4 h-4" />
                </button>

                <button class="button rounded-full bg-red-600 text-white" title="刪除"
                  @click.stop="colorOptions.remove(item)">
                  <FontAwesome icon="trash" class="w-4 h-4" />
                </button>
              </div>
            </div>
          </div>
        </div>
        <!-- END: File Manager Menu -->
      </div>
      <div class="col-span-12 lg:col-span-8 xxl:col-span-9">
        <!-- BEGIN: File Manager Filter -->
        <div class="flex justify-between items-bottom mb-2">
          <div class="flex justify-items-center">
            <span>正面</span>
            <file-uploader v-show="selectedColor.Id" class="border rounded w-24 h-24" id="product-picture" mode="image"
              v-model="selectedColor.Picture.Uri" :action="uploadAction" :autoUpload="true"
              :defaultImage="'https://via.placeholder.com/96x96.png'"
              :headers="{ Authorization: 'Basic ' + $model.getCertificate(0) }"
              @uploaded="colorOptions.savePhoto(selectedColor.Picture)" />
            <span>背面</span>
            <file-uploader v-show="selectedColor.Id" class="border rounded w-24 h-24" id="product-backside-picture" mode="image"
              v-model="selectedColor.BacksidePicture.Uri" :action="uploadAction" :autoUpload="true"
              :defaultImage="'https://via.placeholder.com/96x96.png'"
              :headers="{ Authorization: 'Basic ' + $model.getCertificate(0) }"
              @uploaded="colorOptions.savePhoto(selectedColor.BacksidePicture)" />
          </div>
          <div class="flex items-end align-bottom">
            <button class="button text-white bg-theme-1 shadow-md mr-2" @click="grid.addNewRow"
              v-show="selectedColor.Id">新增尺寸</button>
          </div>
        </div>
        <!-- END: File Manager Filter -->
        <!-- BEGIN: Directory & Files -->
        <div class="w-full">
          <grid ref="grid" v-bind="gridOptions"
            @addNewRow="(row, callback) => { row.ProductItemId = selectedColor.Id; callback(); }"
            @edit="(row, callback) => { row.ProductItemId = selectedColor.Id; callback(); }">
            <!-- <template #toolbar>
              <div class="flex py-2">
                <vxe-button
                  status="success"
                  icon="vxe-icon--plus"
                  @click="grid.addNewRow"
                  >新增帳號</vxe-button
                >
              </div>
            </template> -->

            <template #modal="{ row, submit, reset }">
              <vxe-form :title-width="100" title-align="right" v-bind="formOptions" :data="row" @reset="reset"
                @submit="submit">
                <template #size-id="{ data }">
                  <SelectBox v-bind="sizeSelectorOptions" v-model="data.SizeId" />
                </template>
              </vxe-form>
            </template>
            <template #modal-footer>
              <vxe-button type="submit" status="primary" content="確認"
                @click="$refs.form.validate((errMap) => { if (errMap === undefined) $refs.form.dispatchEvent('submit'); })">
              </vxe-button>
              <vxe-button type="reset" content="重置" @click="$refs.form.dispatchEvent('reset')"></vxe-button>
            </template>
            <template #published="{ row }">
              <vxe-switch v-model="row.Published" open-label="有" close-label="無"></vxe-switch>
            </template>
          </grid>
        </div>
        <!-- END: Directory & Files -->
      </div>
    </div>
    <vxe-modal v-model="colorModal.visible" :title="colorModal.title" width="80%" height="30%" transfer showFooter>
      <div class="h-full">
        <div class="w-full flex mx-5">
          <vxe-form ref="colorForm" v-bind="colorOptions.form" :data="selectedColor" @submit="colorOptions.save">
            <template #color-id="{ data }">
              <SelectBox v-bind="colorOptions.selector" v-model="data.ColorId" />
            </template>
          </vxe-form>
        </div>
      </div>
      <template #footer>
        <vxe-button type="submit" status="primary" content="確認"
          @click="$refs.colorForm.validate((errMap) => { if (errMap === undefined) $refs.colorForm.dispatchEvent('submit'); })">
        </vxe-button>
        <vxe-button type="reset" content="重置" @click="$refs.colorForm.dispatchEvent('reset')"></vxe-button>
      </template>
    </vxe-modal>
    <!-- <photo-upload
      :data="selectedColor?.Picture"
      :show="photoUploadVisible"
      id="color"
      @update:show="photoUploadVisible = $event"
      @save="onPhotoSave"
    ></photo-upload> -->
  </div>
  <!-- END: HTML Table Data -->
</template>

<style scoped>
.swiper-container {
  padding-top: 5px;
  padding-bottom: 30px;
}
</style>
<style>
textarea {
  min-height: 140px;
}

.vxe-select-option {
  max-width: 100% !important;
}
</style>

<script lang="ts">
import { defineComponent, ref, reactive, watch, PropType } from "vue";
import CloudFun, { Condition, Operator } from "@cloudfun/core";
import { Picture, Product, ProductItem } from "@/models/data";
import Grid, { GridOptions } from "@/cloudfun/components/Grid.vue";
// import ColorEdit from "./modal/ColorEdit.vue";
// import SizeEdit from "./SizeEditModal.vue";
// import PhotoUpload from "../photo/Upload.vue";
// import ColorItem from "./ColorItem.vue";
import FileUploader from "@/cloudfun/components/FileUploader.vue";
import SelectBox, { SelectBoxOptions } from "@/cloudfun/components/SelectBox.vue";

export default defineComponent({
  components: {
    Grid,
    // ColorEdit,
    SelectBox,
    // SizeEdit,
    // PhotoUpload,
    FileUploader,
  },
  props: {
    data: {
      type: Object as PropType<Product>,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const model = CloudFun.current?.model;
    const grid = ref<any>();
    const form = ref<any>();
    const colorForm = ref<any>();
    const productColors = ref<ProductItem[]>([]);
    const selectedColor = reactive<ProductItem>({
      Id: 0,
      Picture: { Id: 0, Uri: "" },
      BacksidePicture: { Id: 0, Uri: "" },
      ProductId: 0,
      Published: true
    });
    const files = ref([]);
    const colorModal = reactive({
      title: "",
      visible: false,
    });
    const photoUploadVisible = ref(false);
    const editItem = reactive({});
    const editSizeItem = reactive({});

    const getColors = async (key: number) => {
      productColors.value.length = 0;
      if (!key) return;
      try {
        productColors.value = await model?.dispatch("productItem/query", { condition: new Condition("ProductId", Operator.Equal, key) });
        colorOptions.select(productColors.value?.[0]);
      } catch (failure: any) {
        CloudFun.send("error", {
          subject: "操作失敗！",
          content: failure.message,
        })
      }
    };

    watch(
      () => props.data,
      (value) => {
        getColors(value.Id);
      },
      { immediate: true }
    );

    const gridOptions: GridOptions = {
      title: "商品尺寸",
      canCreate: true,
      canUpdate: true,
      multiselect: false,
      columns: [
        {
          field: "Number",
          title: "貨號",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
        {
          field: "Size.Name",
          title: "尺寸",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
        },
      ],
      promises: {
        query: model
          ? (params) => {
            if (!selectedColor.Id) return undefined as any;
            params.condition = new Condition("ProductItemId", Operator.Equal, selectedColor.Id).and(params.condition!);
            return model.dispatch("productItemSize/query", params)
          } : undefined,
        // queryAll: model
        //   ? () => model.dispatch("productItemSize/queryByProductItem", props.id)
        //   : undefined,
        save: model
          ? (params) => model.dispatch("productItemSize/save", params)
          : undefined,
      },
      modalConfig: { height: "Auto", width: "500" },
    };
    const formOptions = {
      items: [
        {
          field: "Number",
          title: "貨號",
          span: 24,
          itemRender: { name: "$input", props: { placeholder: "請輸入文字" } },
        },
        {
          field: "SizeId",
          title: "尺寸",
          span: 24,
          slots: { default: "size-id" }
        },
        {
          span: 24,
          align: "right",
          itemRender: {
            name: "$buttons",
            children: [
              { props: { type: "submit", content: "確定", status: "primary" } },
              { props: { type: "reset", content: "重置" } },
            ],
          },
        },
      ],
      rules: {
        Number: [{ required: true }],
        SizeId: [{ required: true }],
        // Account: [
        //   {
        //     type: "string",
        //     max: 128,
        //     required: true,
        //     message: "請輸入帳號",
        //   },
        // ],
        // Price: [{ type: "number", required: true, message: "售價" }],
        // MemberPrice: [{ type: "number", required: true, message: "會員價" }],
        // Published: [{ required: true }],
      },
    };

    const sizeSelectorOptions: SelectBoxOptions = {
      showSearch: true,
      transfer: true,
      rowId: "Id",
      placeholder: "選擇尺寸",
      textField: "Name",
      valueField: "Id",
      columns: [
        {
          field: "Name",
          title: "尺寸",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
        },
      ],
      promises: {
        find: (value) => model!.dispatch("size/find", value), // eslint-disable-line
        query: (params) => model!.dispatch("size/query", params), // eslint-disable-line
      },
    }
    const colorOptions = {
      init() {
        Object.assign(selectedColor, {
          Id: 0,
          ColorId: undefined,
          Picture: { Id: 0, Uri: "" },
          BacksidePicture: { Id: 0, Uri: "" },
          ProductId: 0,
          Published: true
        }, { Product: props.data })
      },
      select(item: ProductItem) {
        if (!item) return;
        Object.assign(selectedColor, item, { Product: props.data });
        selectedColor.BacksidePicture = selectedColor.BacksidePicture || { Id: 0, Uri: "" };
        grid.value.reload();
      },
      add() {
        colorOptions.init();
        colorModal.title = "新增顏色";
        colorModal.visible = true;
      },
      edit(item: ProductItem) {
        Object.assign(selectedColor, item, { Product: props.data });
        selectedColor.BacksidePicture = selectedColor.BacksidePicture || { Id: 0, Uri: "" };
        colorModal.title = "修改顏色";
        colorModal.visible = true;
      },
      remove(item: ProductItem) {
        if (confirm("確定刪除!?")) {
          model!.dispatch("productItem/delete", item.Id).then(
            (resp) => {
              getColors(selectedColor.Id);
              colorOptions.init();
              grid.value.reload();
              CloudFun.send("info", {
                subject: "刪除成功",
                content: "產品顏色刪除完成",
              });
            },
            (failure) => {
              console.log("update error", failure);
              CloudFun.send("error", {
                subject: "操作失敗！",
                content: failure,
              });
            }
          );
        }
      },
      async save() {
        try {
          let subject = "";
          let content = "";
          if (selectedColor.Id) {
            await model?.dispatch("productItem/update", { Id: selectedColor.Id, ColorId: selectedColor.ColorId, Published: selectedColor.Published, ProductId: selectedColor.Product!.Id });
            subject = "更新成功";
            content = "產品顏色更新完成";
          } else {
            await model?.dispatch("productItem/insert", { ColorId: selectedColor.ColorId, Published: selectedColor.Published, ProductId: selectedColor.Product!.Id })
            subject = "新增成功";
            content = "產品顏色新增完成";
          }
          getColors(selectedColor.Product!.Id);
          colorOptions.init();
          CloudFun.send("info", {
            subject,
            content,
          });
          colorModal.visible = false;
        } catch (failure: any) {
          console.log("update error", failure);
          CloudFun.send("error", { subject: "操作失敗！", content: failure });
        }
      },
      async savePhoto(picture: Picture, columnIfNotExist = "BacksidePicture") {
        try {
          if (picture.Id) {
            await model!.dispatch("picture/update", picture);
            CloudFun.send("info", {
              subject: "上傳成功",
              content: "圖片上傳成功",
            });
          } else {
            const temp = await model!.dispatch("picture/insert", picture);
            await model!.dispatch("productItem/update", { Id: selectedColor.Id, ColorId: selectedColor.ColorId, Published: selectedColor.Published, ProductId: selectedColor.Product!.Id, [columnIfNotExist]: temp });
            CloudFun.send("info", {
              subject: "上傳成功",
              content: "圖片上傳成功",
            });
          }
        } catch (failure) {
          CloudFun.send("error", { subject: "保存失敗", content: failure });
        }
      },
      form: {
        items: [
          {
            field: "Product.Number",
            title: "產品編號",
            span: 12,
            itemRender: { name: "$input", props: { disabled: true } },
          },
          {
            field: "Product.Name",
            title: "產品名稱",
            span: 12,
            itemRender: { name: "$input", props: { disabled: true } },
          },
          {
            field: "ColorId",
            title: "顏色",
            span: 12,
            slots: { default: "color-id" }
          },
          { field: "Published", title: "顯示", span: 12, itemRender: { name: "$switch", props: { openLabel: '是', closeLabel: '否' } } },
        ],
        rules: {
          ColorId: [{ required: true }]
        },
      },
      selector: {
        showSearch: true,
        transfer: true,
        rowId: "Id",
        placeholder: "選擇顏色",
        textField: "Name",
        valueField: "Id",
        columns: [
          {
            field: "Name",
            title: "顏色",
            showHeaderOverflow: true,
            showOverflow: true,
            sortable: true,
          },
          {
            field: "Number",
            title: "編號",
            showHeaderOverflow: true,
            showOverflow: true,
            resizable: false,
          },
        ],
        promises: {
          find: (value) => model!.dispatch("color/find", value), // eslint-disable-line
          query: (params) => model!.dispatch("color/query", params), // eslint-disable-line
        },
      } as SelectBoxOptions
    }

    return {
      getColors,
      selectedColor,
      grid,
      gridOptions,
      form,
      formOptions,
      sizeSelectorOptions,
      colorForm,
      colorModal,
      colorOptions,
      productColors,
      files,
      baseUrl: process.env.VUE_APP_BACKEND_URL,
      photoUploadVisible,
      editItem,
      editSizeItem,
      uploadAction: `${process.env.VUE_APP_BACKEND_URL}/api/files`,
    };
  },
  methods: {
    // newSize() {
    //   console.log("selectedColor", this.selectedColor);
    //   this.editSizeItem = {
    //     Id: 0,
    //     Number: "",
    //     ProductId: this.product.Id,
    //     Product: {
    //       Id: this.product.Id,
    //       Name: this.product.Name,
    //       Number: this.product.Number,
    //     },
    //     ColorId: this.selectedColor.ColorId,
    //     Color: {
    //       Id: this.selectedColor.ColorId,
    //       Name: this.selectedColor.Color.Name,
    //       Number: this.selectedColor.Color.Number,
    //     },
    //     ProductItemId: this.selectedColor.Id,
    //     OneSize: true,
    //     Sizes: [],
    //   };

    //   console.log("this.editSizeItem", this.editSizeItem);
    // },

    // onSizeSave(params, callback) {
    //   console.log("params", params);

    //   this.$model
    //     .dispatch("productItemSize/save", params)
    //     .then(
    //       (resp) => {
    //         console.log("update success", resp);
    //         // this.getColors(this.data.Id);
    //         this.grid.reload();
    //         CloudFun.send("info", {
    //           subject: params.insertRows.length > 0 ? "新增成功" : "更新成功",
    //           content:
    //             params.insertRows.length > 0
    //               ? "產品尺寸新增完成"
    //               : "產品尺寸更新完成",
    //         });
    //       },
    //       (failure) => {
    //         console.log("update error", failure);
    //         CloudFun.send("error", { subject: "操作失敗！", content: failure });
    //       }
    //     )
    //     .finally();
    //   callback();
    // },
    uploadColorPhoto() {
      this.photoUploadVisible = true;
    },
  },
});
</script>
