<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">產品管理</h2>
      <div class="w-full sm:w-auto flex mt-4 sm:mt-0">
        <div v-show="!grid.isEditing && grid.isRowSelected" id="batch-dropdown" class="dropdown ml-auto mr-2 sm:ml-0">
          <button class="dropdown-toggle button px-2 box bg-blue-500 text-white flex items-center">
            <FontAwesome icon="tasks" type="fas" class="w-4 h-4 mr-1" />
            群組操作
          </button>
          <div class="dropdown-box">
            <div class="dropdown-box__content box dark:bg-dark-1 p-2 w-32">
              <!-- <span
                class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                @click="grid.removeSelectedRows"
              >
                <FontAwesome icon="trash" type="fas" class="w-4 h-4 mr-2" /> 刪除
              </span> -->
              <!-- <span
                class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                @click="grid.onPrint({ mode: 'selected' })"
              >
                <FontAwesome icon="print" type="fas" class="w-4 h-4 mr-2" /> 列印
              </span> -->
              <!-- <span
                class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                @click="grid.onExport({ type: 'csv', mode: 'selected' })"
              >
                <FontAwesome icon="file-csv" type="fas" class="w-4 h-4 mr-2" />
                匯出 CSV
              </span> -->
              <!-- <span
                class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                @click="grid.onExport({ type: 'html', mode: 'selected' })"
              >
                <FontAwesome icon="file" type="fas" class="w-4 h-4 mr-2" /> 匯出 HTML
              </span> -->
            </div>
          </div>
        </div>
        <button v-show="!grid.isEditing" class="button text-white bg-theme-1 shadow-md mr-2 flex items-center"
          @click="grid.addNewRow">
          <FontAwesome icon="plus" type="fas" class="w-4 h-4 mr-1" />
          新增
        </button>
      </div>
    </div>
    <!-- BEGIN: HTML Table Data -->
    <div class="intro-y box p-5 mt-5">
      <div class="flex flex-col sm:flex-row sm:items-end xl:items-start">
        <div class="xl:flex sm:mr-auto">
          <div class="sm:flex items-center ml-1 sm:mr-2 mt-2 xl:mt-0">
            <input type="text" class="input w-full sm:w-40 xxl:w-full mt-2 sm:mt-0 border" v-model.trim="grid.keyword"
              placeholder="請輸入關鍵字" @keyup.enter="grid.refresh" />
          </div>
          <div class="mt-2 xl:mt-0">
            <button type="button" class="button w-full sm:w-16 bg-theme-1 text-white" @click="grid.refresh">
              搜尋
            </button>
          </div>
        </div>
        <div class="flex mt-2 sm:mt-0">
          <!-- <button
            class="button w-1/2 sm:w-auto flex items-center border text-gray-700 dark:bg-dark-5 dark:text-gray-300"
            @click="grid.onPrint({ mode: 'curret' })"
          >
            <FontAwesome icon="print" class="w-4 h-4 mr-2" /> 列印
          </button> -->
          <!-- <div class="dropdown w-1/2 sm:w-auto ml-2">
            <button
              class="dropdown-toggle button w-full sm:w-auto flex items-center border text-gray-700 dark:bg-dark-5 dark:text-gray-300"
            >
              <FontAwesome icon="file-export" class="w-4 h-4 mr-2" /> 匯出
              <ChevronDownIcon class="w-4 h-4 ml-auto sm:ml-2" />
            </button>
            <div class="dropdown-box">
              <div class="dropdown-box__content box dark:bg-dark-1 p-2">
                <a
                  href="javascript:;"
                  class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                  @click="grid.onExport({ type: 'csv' })"
                >
                  <FontAwesome icon="file-csv" class="w-4 h-4 mr-2" /> CSV
                </a> -->
          <!-- <a
                  href="javascript:;"
                  class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                  @click="grid.onExport({ type: 'html' })"
                >
                  <FontAwesome icon="file" type="fas" class="w-4 h-4 mr-2" /> HTML
                </a> -->
          <!-- </div>
            </div>
          </div> -->
        </div>
      </div>
      <div class="overflow-x-auto scrollbar-hidden mt-2">
        <grid ref="grid" v-bind="gridOptions" @addNewRow="
          (row, callback) => {
            row.Published = true;
            callback();
          }
        " @edit="onGridEdit" @removeSelectedRows="onGridRemoveSelectedRows">
          <!-- <template #operate="{ row }">
            <slot name="operate" :row="row">
              <div class="flex" v-if="!isEditing">
                <button
                  class="button rounded-full mr-1 mb-2 bg-theme-1 text-white"
                  title="編輯"
                  @click="edit(row)"
                >
                  <FontAwesome icon="highlighter" class="w-4 h-4" />
                </button>
                <button
                  class="button rounded-full mr-1 mb-2 bg-theme-6 text-white"
                  title="取消"
                  @click="remove(row)"
                >
                  <FontAwesome icon="trash" class="w-4 h-4" />
                </button>
              </div>
            </slot>
          </template> -->

          <template #modal="{ row, reset }">
            <div class="post intro-y overflow-hidden box mt-5">
              <div class="post__tabs nav-tabs flex flex-col sm:flex-row bg-gray-300 dark:bg-dark-2 text-gray-600">
                <Tippy tag="a" content="顯示產品資訊" data-toggle="tab" data-target="#info" href="javascript:;"
                  class="w-full sm:w-40 py-4 text-center flex justify-center items-center active">
                  <UsersIcon class="w-4 h-4 mr-2" /> 產品資訊
                </Tippy>
                <Tippy tag="a" content="顯示產品顏色" data-toggle="tab" data-target="#store" href="javascript:;"
                  v-show="row.Id" class="w-full sm:w-40 py-4 text-center flex justify-center items-center">
                  <UsersIcon class="w-4 h-4 mr-2" /> 產品顏色
                </Tippy>
                <!-- <Tippy tag="a" content="產品介紹說明內容維護" data-toggle="tab" data-target="#description" href="javascript:;"
                  class="w-full sm:w-40 py-4 text-center flex justify-center items-center">
                  <TruckIcon class="w-4 h-4 mr-2" /> 產品介紹說明
                </Tippy> -->
                <Tippy tag="a" content="顯示經銷商目前購物車內容" data-toggle="tab" data-target="#picture-album" href="javascript:;"
                  v-show="row.Id" class="w-full sm:w-40 py-4 text-center flex justify-center items-center">
                  <ShoppingCartIcon class="w-4 h-4 mr-2" /> 產品圖集
                </Tippy>
              </div>
              <div class="post__content tab-content">
                <div id="info" class="tab-content__pane px-5 active">
                  <div>
                    <vxe-form v-bind="formOptions" :data="row" @submit="submitForm" @reset="reset">
                      <template #column-photo-and-remark="{ data }">
                        <FileUploader :ref="el => uploader = el" id="user-photo-uri"
                          style="min-width: 150px; max-width: 150px; min-height: 150px; max-height: 180px;"
                          class="mr-10" mode="image" :modelValue="data.Photo?.Uri" :action="uploadAction"
                          :autoUpload="true" :limitedWidth="150" :limitedHeight="150" :headers="{ Authorization: 'Basic ' + $model.getCertificate(0) }"
                          @update:modelValue="(value) => { if (data.Photo) data.Photo.Uri = value; else data.Photo = { Uri: value }; }"
                          @filter="uploaderFilter" />
                      </template>
                    </vxe-form>
                  </div>
                </div>
                <div id="store" class="tab-content__pane px-5">
                  <ColorGrid :data="row"></ColorGrid>
                </div>
                <div id="picture-album" class="tab-content__pane px-5">
                  <PictureAlbum :albumId="row.PictureAlbumId"></PictureAlbum>
                </div>
              </div>
            </div>
          </template>
        </grid>
      </div>
    </div>
    <!-- END: HTML Table Data -->
  </div>
</template>

<style scoped>
.swiper-container {
  padding-top: 5px;
  padding-bottom: 30px;
}
</style>
<style>
textarea {
  min-height: 140px;
}

.vxe-select-option {
  max-width: 100% !important;
}
</style>

<script lang="ts">
import CloudFun, {
  defineComponent,
  ref,
} from "@cloudfun/core";
import Grid, { GridOptions } from "@/cloudfun/components/Grid.vue";
import FileUploader from "@/cloudfun/components/FileUploader.vue";
import ColorGrid from "@/components/product/Color.vue";
import PictureAlbum from "@/components/product/Album.vue";
import { VueUploadItem } from "vue-upload-component";

export default defineComponent({
  components: {
    Grid,
    FileUploader,
    ColorGrid,
    PictureAlbum
  },
  setup() {
    const model = CloudFun.current?.model;
    const grid = ref<any>({});
    const uploader = ref<any>({});

    var printColumns = [
      { field: "Name" },
      { field: "Author" },
      { field: "Price" },
      { field: "MemberPrice" },
      { field: "PublishedString" },
      { field: "Ordinal" },
    ];

    const gridOptions: GridOptions = {
      title: "產品資料",
      canCreate: true,
      canUpdate: true,
      multiselect: false,
      toolbarConfig: {
        custom: true,
        refresh: true,
      },
      // pagerConfig: {
      //   currentPage: 1,
      //   pageSize: 10,
      //   pageSizes: [5, 10, 20],
      //   layouts: [
      //     "PrevJump",
      //     "PrevPage",
      //     "Jump",
      //     "PageCount",
      //     "NextPage",
      //     "NextJump",
      //     "Sizes",
      //     "Total",
      //   ],
      // },
      printConfig: {
        sheetName: "產品清單",
        columns: printColumns,
        modes: ["current", "selected", "all"],
      },
      exportConfig: {
        filename: "產品清單",
        type: "csv",
        types: ["html", "csv"],
        mode: "all",
        modes: ["current", "selected", "all"],
        columns: printColumns,
      },
      columns: [
        {
          field: "Number",
          title: "品號",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
          width: "180",
          fixed: "left",
          editRender: {
            name: "$input",
            immediate: true,
            attrs: { type: "text" },
          },
        },
        {
          field: "Name",
          title: "品名",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
          minWidth: "150",
          editRender: {
            name: "$input",
            immediate: true,
            attrs: { type: "text" },
          },
        },
        // {
        //   field: "Country.Name",
        //   title: "產地",
        //   showHeaderOverflow: true,
        //   showOverflow: true,
        //   sortable: true,
        //   width: "180",
        //   editRender: {
        //     name: "$input",
        //     immediate: true,
        //     attrs: { type: "text" },
        //   },
        // },
        // {
        //   field: "Ordinal",
        //   title: "排序值",
        //   showHeaderOverflow: true,
        //   showOverflow: true,
        //   sortable: true,
        //   width: "100",
        //   editRender: {
        //     name: "$input",
        //     immediate: true,
        //     attrs: { type: "text" },
        //   },
        // },
        {
          field: "Published",
          title: "上架",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
          width: "100",
          resizable: false,
          formatter({ cellValue }) {
            return cellValue ? "上架" : "下架";
          }
        },
      ],
      promises: {
        query: model ? (params) => model.dispatch("product/query", params) : undefined,
        queryAll: model ? (params) => model.dispatch("product/query", params) : undefined,
        save: model ? (params) => model.dispatch("product/save", params) : undefined,
      },
      modalConfig: { width: "80%", height: "80%" },
    };
    const formOptions = {
      items: [
        { field: "Photo.Uri", title: "圖片", span: 24, slots: { default: "column-photo-and-remark" } },
        {
          field: "Number",
          title: "產品編號",
          span: 12,
          itemRender: { name: "$input", props: { placeholder: "請輸入文字" } },
        },
        {
          field: "Name",
          title: "產品名稱",
          span: 12,
          itemRender: { name: "$input", props: { placeholder: "請輸入文字" } },
        },
        {
          field: "SalePice",
          title: "價格",
          span: 12,
          itemRender: { name: "$input", props: { type: "number", placeholder: "請輸入價格" } },
        },
        { field: "Published", title: "是否上架", span: 12, itemRender: { name: "$switch", props: { openLabel: '上架', closeLabel: '下架' } } },
        {
          field: "Description.Content",
          title: "產品介紹",
          span: 24,
          itemRender: { name: "$textarea", props: { placeholder: "請輸入產品介紹" } },
        },
        {
          span: 24,
          align: "right",
          itemRender: {
            name: "$buttons",
            children: [
              { props: { type: "submit", content: "確定", status: "primary" } },
              { props: { type: "reset", content: "重置" } },
            ],
          },
        },
      ],
      rules: {
        Name: [{ required: true }],
        // Price: [{ type: "number", required: true, message: "售價" }],
        // MemberPrice: [{ type: "number", required: true, message: "會員價" }],
        // Published: [{ required: true }],
      },
    };

    const submitForm = async () => {
      if (grid.value.editingRow.Id) {
        grid.value.editingRow = await model?.dispatch("product/update", grid.value.editingRow);
      } else {
        grid.value.editingRow = await model?.dispatch("product/insert", grid.value.editingRow);
      }
      grid.value.reload();
    }

    return {
      grid,
      uploader,
      gridOptions,
      formOptions,
      submitForm,
      uploadAction: `${process.env.VUE_APP_BACKEND_URL}/api/Files`,
    };
  },
  methods: {
    async onGridEdit(row: any, callback: any) {
      const entity = row.Id
        ? await this.$model.dispatch("product/find", row.Id)
        : undefined;

      if (entity) Object.assign(row, entity);
      callback();
    },
    onGridRemoveSelectedRows(rows: any, callback: any) {
      cash("#batch-dropdown").dropdown("hide");
      callback();
    },
    async uploaderFilter(current: VueUploadItem, original: VueUploadItem, prevent: any) {
      if (!current || !current.name) return prevent();
      if (!/\.(png|gif|jpg|jpeg)$/i.test(current.name)) {
        alert("未支援此種圖片格式");
        return prevent();
      }
    }
  },
});
</script>
